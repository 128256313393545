<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <slot />
    </v-layout>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
