<template>
  <v-flex xs-12 sm10 md6>
    <v-card class="elevation-12">
      <v-card-title class="primary align-self-center">
        <h2 class="headline ml-4 white--text">Reset Password</h2>
      </v-card-title>
      <v-card-text class="pt-10 px-10">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            prepend-inner-icon="mdi-email"
            label="Email"
            type="email"
            v-model="email"
            :rules="emailRules"
            hint="Enter your registered email address"
            required
            outlined
            dense
          ></v-text-field>
          <div class="text-center">
            <div>
              <router-link to="/login">Back to Login</router-link>
            </div>
          </div>
        </v-form>
      </v-card-text>
      <div class="text-center pb-10">
        <v-btn
          color="primary white--text"
          :disabled="!valid"
          @click="resetPassword"
          >Reset</v-btn
        >
      </div>
    </v-card>
  </v-flex>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout.vue";
import { auth } from "../services/firebase";
import { sendPasswordResetEmail } from "firebase/auth";

export default {
  data() {
    return {
      valid: true,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },

  created() {
    this.$emit(`update:layout`, AuthLayout);
  },

  methods: {
    async resetPassword() {
      if (this.$refs.form.validate()) {
        sendPasswordResetEmail(auth, this.email)
          .then(() => {
            this.$toasted.global.optimus_info({
              message:
                "Password reset link sent to your registered email address.",
            });
          })
          .catch((error) => {
            this.$toasted.global.optimus_info({
              message: `Password reset link sent to your registered email address. ${error.message}`,
            });
          });
      }
    },
  },
};
</script>
